// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-anmeldung-js": () => import("./../../../src/pages/anmeldung.js" /* webpackChunkName: "component---src-pages-anmeldung-js" */),
  "component---src-pages-asf-seminar-js": () => import("./../../../src/pages/asf-seminar.js" /* webpackChunkName: "component---src-pages-asf-seminar-js" */),
  "component---src-pages-auto-index-js": () => import("./../../../src/pages/auto/index.js" /* webpackChunkName: "component---src-pages-auto-index-js" */),
  "component---src-pages-auto-klasse-b-196-js": () => import("./../../../src/pages/auto/klasse-b196.js" /* webpackChunkName: "component---src-pages-auto-klasse-b-196-js" */),
  "component---src-pages-auto-klasse-b-197-js": () => import("./../../../src/pages/auto/klasse-b197.js" /* webpackChunkName: "component---src-pages-auto-klasse-b-197-js" */),
  "component---src-pages-auto-klasse-b-js": () => import("./../../../src/pages/auto/klasse-b.js" /* webpackChunkName: "component---src-pages-auto-klasse-b-js" */),
  "component---src-pages-auto-klasse-be-js": () => import("./../../../src/pages/auto/klasse-be.js" /* webpackChunkName: "component---src-pages-auto-klasse-be-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-fahrschul-wechsel-js": () => import("./../../../src/pages/fahrschul-wechsel.js" /* webpackChunkName: "component---src-pages-fahrschul-wechsel-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-motorrad-index-js": () => import("./../../../src/pages/motorrad/index.js" /* webpackChunkName: "component---src-pages-motorrad-index-js" */),
  "component---src-pages-motorrad-klasse-a-1-js": () => import("./../../../src/pages/motorrad/klasse-a1.js" /* webpackChunkName: "component---src-pages-motorrad-klasse-a-1-js" */),
  "component---src-pages-motorrad-klasse-a-2-js": () => import("./../../../src/pages/motorrad/klasse-a2.js" /* webpackChunkName: "component---src-pages-motorrad-klasse-a-2-js" */),
  "component---src-pages-motorrad-klasse-a-js": () => import("./../../../src/pages/motorrad/klasse-a.js" /* webpackChunkName: "component---src-pages-motorrad-klasse-a-js" */),
  "component---src-pages-motorrad-klasse-am-js": () => import("./../../../src/pages/motorrad/klasse-am.js" /* webpackChunkName: "component---src-pages-motorrad-klasse-am-js" */)
}

